define('typeahead-app', [
    'jquery',
    'bloodhound',
    'baseUrl',
    'typeahead.js',
    'endsWith'
], function (
    $,
    Bloodhound,
    baseUrl
) {
    var devSuffix = '-dev';

    var engine = new Bloodhound({
        datumTokenizer: function (obj) {
            var tokens = Bloodhound.tokenizers.whitespace(obj.name);
            tokens.push(obj.key);
            return tokens;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        sorter: function (a, b) {
            return a.key.endsWith(devSuffix) - b.key.endsWith(devSuffix);
        },
        identify: function (obj) {
            return obj.key;
        },
        prefetch: {
            url: baseUrl + '/typeahead/apps',
            cache: document.appPrefetchCache
        }
    });

    var loading = 'loading';
    var error = 'error';
    var status = loading;

    var options = {
        highlight: true,
        hint: false
    }
    var dataSet = {
        display: 'key',
        source: engine.ttAdapter(),
        templates: {
            notFound: function () {
                if (status === loading) {
                    return '<em class="text-muted tt-message">loading...</em>';
                } else if (status === error) {
                    return '<em class="text-danger tt-message">service error</em>';
                } else {
                    return '<em class="text-danger tt-message">no matches</em>';
                }
            },
            suggestion: function (obj) {
                return '<div>' + obj.name + '</div>';
            }
        }
    }

    function refresh() {
        elms.filter(':focus')
            .typeahead('destroy')
            .typeahead(options, dataSet)
            .focus();
    }

    var elms = $('[data-typeahead-app]')
        .typeahead(options, dataSet);

    elms.filter('[autofocus]')
        .first()
        .focus();

    engine
        .initialize()
        .done(function () {
            status = null;
            refresh();
        })
        .fail(function () {
            status = error;
            refresh();
        });

    $('[data-typeahead-submit]').on('typeahead:select', function () {
        $(this.form).submit();
    });
});