requirejs.config({
    baseUrl: window.baseUrl + '/wwwroot/js',
    paths: {
        'jquery': [
            'https://code.jquery.com/jquery-2.1.4.min',
            'jquery.min'
        ],
        'bootstrap': [
            'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min',
            'bootstrap.min'
        ],
        'jquery.validate': [
            'https://ajax.aspnetcdn.com/ajax/jquery.validate/1.14.0/jquery.validate.min',
            'jquery.validate.min'
        ],
        'jquery.validate.unobtrusive': [
            'https://ajax.aspnetcdn.com/ajax/mvc/5.2.3/jquery.validate.unobtrusive.min',
            'jquery.validate.unobtrusive.min'
        ]
    },
    shim: {
        'jquery': {
            exports: '$'
        },
        'bootstrap': {
            deps: ['jquery']
        },
        'jquery.validate': {
            deps: ['jquery'],
            exports: '$.validator'
        },
        'jquery.validate.unobtrusive': {
            deps: ['jquery.validate']
        }
    }
})
