define('validator', [
    'jquery',
    'jquery.validate',
    'jquery.validate.unobtrusive'
], function ($) {
    // run onload (after unobtrusive validation has been applied)
    $(function () {
        $('form').each(function () {
            var validator = $(this).data('validator');
            if (!validator) return;

            // validate hidden fields
            validator.settings.ignore = "";

            // add bootstrap .has-error to .form-group
            // fire validator:x validation hooks
            var oldHighlight = validator.settings.highlight;
            validator.settings.highlight = function (elm, error, valid) {
                oldHighlight.apply(this, arguments);
                $(elm).trigger('validator:highlight', [error, valid])
                    .parents('.form-group').addClass('has-error');
            };

            var oldUnhighlight = validator.settings.unhighlight;
            validator.settings.unhighlight = function (elm, error, valid) {
                oldUnhighlight.apply(this, arguments);
                $(elm).trigger('validator:unhighlight', [error, valid])
                    .parents('.form-group').removeClass('has-error');
            }
        }).on('submit', function () {
            var validator = $(this).data('validator');

            if (!validator || validator.form()) {
                $(this).find('input,select,textarea').attr('readonly', true);
                $(this).find('button').attr('disabled', true);
            }
        });
    });
})