define('bootstrap-fallback', ['jquery'], function ($) {
    $(function () {
        var test = $('<span class="hidden">test</span>').appendTo('body');

        if (test.is(':visible')) {
            console.log('using local bootstrap');
            $('head').append('<link href="wwwroot/css/bootstrap.min.css" rel="stylesheet">');
        }

        test.remove();
    });
})